import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';

export const BreadcrumbSchema = ({ page }) => {
  const data = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Probemas',
        item: 'https://probemas.com'
      }
    ]
  };
  if (page.slug !== 'index') {
    data['itemListElement'].push({
      '@type': 'ListItem',
      position: 2,
      name: page.title,
      item: `${process.env.ORIGIN}/${page.slug}`
    });
  }
  return (
    <Head>
      <script {...jsonLdScriptProps(data)} />
    </Head>
  );
};

export const ratingSchemaData = reviewsStats => {
  return {
    '@type': 'AggregateRating',
    ratingValue: reviewsStats['averageRating'],
    bestRating: '5',
    worstRating: '1',
    ratingCount: reviewsStats['totalReviews'],
    reviewCount: reviewsStats['totalReviews']
  };
};

export const reviewsSchemaData = reviews => {
  const data = [];
  reviews.slice(0, 5).map((r, index) =>
    data.push({
      '@type': 'Review',
      name: `Review ${index + 1}`,
      reviewBody: r['comment'],
      reviewRating: {
        '@type': 'Rating',
        ratingValue: r['rating'],
        bestRating: '5',
        worstRating: '1'
      },
      datePublished: r['timeago'],
      author: {
        '@type': 'Person',
        name: r['username']
      },
      publisher: { '@type': 'Organization', name: 'Reviews.io' }
    })
  );
  return data;
};

export const FaqSchema = ({ items }) => {
  const faqItems = [];
  items.map(({ node: faq }, index) =>
    faqItems.push({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: { '@type': 'Answer', text: faq.answer }
    })
  );
  return (
    <Head>
      <script
        {...jsonLdScriptProps({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: faqItems
        })}
      />
    </Head>
  );
};

export const SchemaOrg = ({ schema, reviews }) => {
  if (reviews) {
    schema['aggregateRating'] = ratingSchemaData(reviews);
    schema['review'] = reviewsSchemaData(reviews['reviews']);
  }
  return (
    <Head>
      <script {...jsonLdScriptProps(schema)} />
    </Head>
  );
};

import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    maxWidth: 414,
    marginBottom: 10,
    // [theme.breakpoints.down('xs')]: {
    //   borderTopLeftRadius: 0,
    //   borderTopRightRadius: 0
    // },
    zIndex: 1,
    position: 'relative',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 50
    }
  },
  ornament: {
    [theme.breakpoints.up('sm')]: {
      '&:before': {
        content: '""', // should be doublequoted
        display: 'block',
        position: 'absolute',
        width: 238,
        height: 54,
        left: 'calc(50% - 128.5px)',
        top: -35,
        background: 'url("/images/ornament_top.png") left top no-repeat'
      },
      '&:after': {
        content: '""', // should be doublequoted to work
        display: 'block',
        position: 'absolute',
        width: 237,
        height: 54,
        left: 'calc(50% - 129px)',
        bottom: -44,
        background: 'url("/images/ornament_bottom.png") right top no-repeat'
      }
    }
  },
  notRoundedCorner: {
    [theme.breakpoints.down('xs')]: {
      borderTopLeftRadius: '0 !important',
      borderTopRightRadius: '0 !important'
    }
  },
  widget: {
    backgroundColor: '#EFFAFE !important',
    border: '1px solid #56CCF2',
    borderTop: 'none',
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important'
  },
  content: {
    minHeight: 286,
    padding: '18px 24px 23px',
    border: '1px solid #13324c',
    borderTop: 'none',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  },
  widgetContent: {
    paddingLeft: 12,
    paddingRight: 12
  },
  number: {
    width: '100%',
    maxWidth: '100px',
    textAlign: 'center'
    // '& input': {
    //   padding: theme.spacing(0, 1)
    // }
  },
  sellPrice: {
    display: 'flex',
    fontSize: 28,
    fontWeight: 'bold',
    color: '#ffffff',

    '@media (max-width:425px)': {
      fontSize: 23
    }
  },
  cardHeader: {
    backgroundColor: '#142241',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    // justifyContent: 'space-around',
    height: 97,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,

    '@media (max-width:425px)': {
      height: 70
    }
    // padding: '16px 35px 11px'
  },
  media: {
    width: 'initial',
    maxWidth: 165,
    maxHeight: 65,
    display: 'block'
  },
  button: {
    height: 50
  },
  buttonLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '1.5rem',
    letterSpacing: 0.42
  },
  prices: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: 15,
    color: props => (props.isWidget ? '#333333' : theme.palette.common.white)
  },
  quantityError: {
    position: 'absolute',
    bottom: -15,
    left: 0
    // [theme.breakpoints.only('sm')]: {
    //   left: 110,
    //   width: 'calc(100% - 110px)' // 75 + 40 - 5
    // width: '177%'
    // }
  },
  inputError: {
    position: 'absolute',
    bottom: -19
  },
  fullWidthInputError: {
    right: 0,
    width: '50%'
  },
  styledInput: {
    position: 'initial'
  },
  priceItem: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 45%',
    maxWidth: '45%'
  },
  priceItemLeft: {
    flex: '1 0 40%',
    maxWidth: '40%'
  },
  priceItemRight: {
    flex: '1 0 45%',
    maxWidth: '45%'
  },
  center: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 4
    }
  },
  pricePrefix: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // width: 50,
    height: 50,
    color: '#142241',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      // width: 40,
      justifyContent: 'center'
    }
  },
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  pricePrefixSign: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45rem'
    }
  },
  pricePrefixText: {
    fontWeight: 'bold',
    fontSize: '0.6rem',
    lineHeight: '10px',
    textTransform: 'uppercase',
    letterSpacing: '0.46px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.55rem'
    }
  },
  syncArrows: {
    color: '#b2dbee'
  },
  input: {
    fontSize: '1rem'
  },
  // textInput: {
  //   marginBottom: 19,
  //   marginTop: 0
  // },
  fieldInput: {
    boxShadow: 'inset 2px 2px 3px rgba(0, 0, 0, 0.11)',
    border: '1px solid rgba(157, 157, 157, 0.5)',
    // height: 41,
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem'
      // height: 34
    }
  },
  priceInput: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    // maxWidth: 96,
    position: 'initial',
    '& input': {
      fontSize: '1.2rem',
      paddingLeft: 5,
      paddingRight: 5,
      textAlign: 'center'
    }
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 82
    // }
  },
  '@media (max-width:375px)': {
    // priceInput: {
    //   maxWidth: 75
    // },
    // pricePrefix: {
    //   width: 40
    // },
    quantityError: {
      margin: 0
    },
    content: {
      padding: 15
    }
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    color: '#1ba260',
    backgroundColor: 'rgba(86, 204, 242, 0.22)',
    paddingLeft: 8,
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginBottom: 10,
    '& svg': {
      color: '#51ba41',
      fontSize: '1rem',
      marginRight: 5
    }
  },
  dropDownIcon: {
    position: 'absolute',
    bottom: -7,
    '& svg': {
      fontSize: '1em'
    }
  },
  tilda: {
    position: 'absolute',
    marginTop: '0 !important',
    marginLeft: -10,
    '& > p': {
      fontSize: '1.3rem',
      color: theme.palette.text.primary
    }
  },
  cardTitle: {
    fontSize: 22,
    color: '#333333',
    fontWeight: 700
  },
  cardDesc: {
    color: '#0E4798',
    fontSize: 14,
    marginBottom: 16
  },
  cardInfo: {
    color: '#68c677',
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 25
  },
  pointsTextBox: {
    position: 'relative'
  },
  pointsText: {
    color: 'green',
    fontSize: '0.9rem',
    position: 'absolute',
    top: -17,
    width: '100%'
  },
  infoText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      lineHeight: '0.9rem'
    }
  },
  goldTitle: {
    display: 'inline-block',
    fontSize: 32,
    fontWeight: 700,
    position: 'relative',
    color: '#e9b109',
    marginRight: 'auto',
    textTransform: 'uppercase',

    '@media (max-width:425px)': {
      fontSize: 23
    }
  },
  imageWrapper: {
    width: 16,
    height: 28,
    position: 'relative',

    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: '100% !important',
      height: '100% !important'
    }
  }
}));

import React from 'react';
import { TextField } from 'formik-material-ui';
import { Field, useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
  root: {
    color: 'rgba(51, 51, 51, 1)',
    marginTop: 5,
    marginBottom: 5,
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  input: {
    // marginTop: 5,
    // marginBottom: 5,
    boxShadow: 'inset 2px 2px 3px rgba(0, 0, 0, 0.11)',
    border: '1px solid rgba(157, 157, 157, 0.5)',
    fontSize: 20,
    borderRadius: 5,
    '& input::placeholder': {
      fontSize: 16
      // textTransform: 'uppercase'
      // color: '#BDBDBD'
    }
    // '& input': {
    //   height: '100%',
    //   maxHeight: 50,
    // },
    // hide placeholder right on click
    // '& input:focus::placeholder': {
    //   color: 'transparent !important'
    // }
    // '& input:-webkit-autofill': {
    //   height: '100%',
    //   padding: 0
    // }
  },
  // input50: {
  //   height: 50,
  //   '& input': {
  //     height: 50,
  //     padding: 'initial'
  //   }
  // },
  withLabel: {
    '& input': {
      // padding: '20px 12px 6px'
      padding: 'initial',
      // paddingLeft: 15,
      paddingRight: 15
    }
  },
  noLabel: {
    '& input': {
      paddingLeft: 12
    }
  },
  multiline: {
    boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.25)'
  },
  label: {
    // fontSize: '1rem',
    // fontWeight: 500,
    // color: 'rgba(51, 51, 51, 1)',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  labelWithSvg: {
    paddingLeft: 34
  },
  grey: {
    backgroundColor: '#F2F2F2',
    '&:hover': {
      backgroundColor: '#F2F2F2'
    },
    '&:focus-within': {
      backgroundColor: '#F2F2F2'
    }
  },
  white: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    },
    '&:focus-within': {
      backgroundColor: '#FFFFFF'
    }
  },
  darkBlue: {
    backgroundColor: '#162138',
    fontSize: 16,
    color: 'rgba(255,255,255, 1)',
    '&:focus-within': {
      backgroundColor: '#162138',
      boxShadow: '0 0 0 1px rgba(255,255,255,0.1)'
    },
    '&:hover': {
      backgroundColor: '#162138',
      boxShadow: '0 0 0 1px rgba(255,255,255,0.1)'
    }
  },
  darkBlueContained: {
    backgroundColor: '#1c273f',
    fontSize: 16,
    color: 'rgba(255,255,255, 1)',
    '&:focus-within': {
      backgroundColor: '#162138',
      boxShadow: '0 0 0 1px rgba(255,255,255,0.1)'
    }
  },
  emailError: {
    backgroundColor: '#162138',
    fontSize: 16,
    color: 'rgba(255,255,255, 1)',
    boxShadow: '0 0 0 1px hsla(0, 100%, 66%, 0.5)',
    '&:focus-within': {
      backgroundColor: '#162138',
      boxShadow: '0 0 0 1px hsla(0, 100%, 66%, 0.5)'
    }
  },
  disabled: {
    backgroundColor: '#CCCCCC !important'
  },
  errorText: {
    color: '#FFFFFF !important',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: '#D62020',
    height: '50px',
    position: 'absolute',
    // top: 5,
    margin: 0,
    paddingLeft: 15,
    paddingRight: 15
  },
  fullWidthInputError: {
    right: 0,
    width: '50%'
  },
  hidden: {
    display: 'none'
  },
  adornment: {
    marginTop: 2
  },
  error: {
    borderColor: 'red !important'
  },
  errorHelperText: {
    fontSize: 11,
    color: 'hsla(0, 100%, 66%, 1)',
    width: '-webkit-fill-available',
    marginLeft: 5
    // fontSize: 10,
    // textTransform: 'uppercase',
    // fontWeight: 'bold'
  }
});

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      decimalScale={2}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
}

const FilledInput = ({
  color,
  numeric,
  className,
  onUpdate,
  styledError,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest);
  const classes = useStyles();
  const InputProps = {
    ...rest.InputProps,
    id: rest.id,
    name: rest.name,
    disableUnderline: true,
    className: clsx(
      rest.InputProps && rest.InputProps.className,
      classes.input,
      classes[color],
      // rest.label && classes.withLabel,
      !(rest.InputProps && rest.InputProps.startAdornment) && classes.noLabel
    ),
    classes: {
      disabled: classes.disabled,
      error: classes.error
    }
  };
  if (styledError) {
    rest.FormHelperTextProps = {
      ...rest.FormHelperTextProps,
      className: clsx(
        classes.errorText,
        rest.fullWidth && classes.fullWidthInputError
      )
    };
  } else {
    rest.FormHelperTextProps = {
      ...rest.FormHelperTextProps,
      className: rest.FormHelperTextProps
        ? clsx(rest.FormHelperTextProps.className, classes.errorHelperText)
        : classes.errorHelperText
    };
  }
  if (numeric) {
    InputProps.inputComponent = NumberFormatCustom;
    // workaround to make it work with react-number-format
    InputProps.onChange = e => {
      if (isNaN(parseFloat(e.target.value))) {
        return false;
      }
      // update inner value only if we didn't provide another way to avoid recursion
      if (!rest.onChangeCapture) {
        helpers.setValue(e.target.value);
        // NOTE: make sure you manually handled setValue in  `onChangeCapture` event
      }
    };
  }
  return (
    <Field
      {...rest}
      variant="filled"
      component={TextField}
      className={clsx(classes.root, className)}
      InputProps={InputProps}
      InputLabelProps={{
        shrink: false,
        // disableAnimation: true,
        className: clsx(
          classes.label,
          field.value && classes.hidden,
          InputProps.startAdornment && classes.labelWithSvg
        )
      }}
    />
  );
};

FilledInput.propTypes = {
  color: PropTypes.oneOf(['white', 'grey']),
  numeric: PropTypes.bool,
  styledError: PropTypes.bool
};

FilledInput.defaultProps = {
  color: 'white',
  styledError: false,
  numeric: false
};

export default FilledInput;

import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold',
    padding: '9px 16px 10px',
    '&:hover': {
      opacity: 0.9
    }
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: 'rgba(233, 177, 9, 1)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  secondary: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: 'linear-gradient(0deg,#c8a956 51%,#e9c564 55%)'
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.light
    // }
  },
  disabled: {
    opacity: 0.5,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.35) !important',
    color: 'inherit !important'
  },
  primaryDisabled: {
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  secondaryDisabled: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  arrows: {
    boxShadow: '0 9px 11px rgba(0, 0, 0, 0.33)',
    textShadow: '0 1px 0 rgba(0, 0, 0, 0.5)',
    '&:before': {
      content: '""', // should be doublequoted
      display: 'block',
      position: 'absolute',
      width: 13,
      height: 19,
      left: -2,
      top: 'calc(50% - 9.5px)',
      background: 'url("/images/button_left_arrow.png") left top no-repeat'
    },
    '&:after': {
      content: '""', // should be doublequoted to work
      display: 'block',
      position: 'absolute',
      width: 13,
      height: 19,
      right: -2,
      top: 'calc(50% - 9.5px)',
      background: 'url("/images/button_right_arrow.png") right top no-repeat'
    }
  }
}));

export const MainButton = React.forwardRef(
  ({ color, className, title, arrows, children, ...props }, ref) => {
    const classes = useStyles();
    return (
      <Button
        ref={ref}
        className={clsx(
          classes.root,
          classes[color],
          arrows && classes.arrows,
          className
        )}
        classes={{
          disabled: clsx(classes.disabled, classes[`${color}Disabled`])
        }}
        {...props}
      >
        {title || children}
      </Button>
    );
  }
);

MainButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

MainButton.defaultProps = {
  type: 'submit',
  variant: 'contained'
};

import React from 'react';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    // marginBottom: 25,
    textAlign: 'center',
    marginTop: 15
  },
  title: {
    color: '#000000',
    fontWeight: 'bold'
  },
  middle: {
    margin: '0 auto'
  },
  pageHeading: {
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '2.69px',
    textShadow: 'none',
    background: 'linear-gradient(to right,#142241 0%,#0e4798 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    [theme.breakpoints.up('md')]: {
      fontSize: 68
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 45,
      paddingLeft: 15,
      paddingRight: 15
    },
    [theme.breakpoints.down('xs')]: {
      letterSpacing: 'initial',
      fontSize: 23,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  blueTitle: {
    display: 'inline-block',
    color: '#0e4798',
    [theme.breakpoints.down('md')]: {
      color: '#ffffff'
    },
    background: 'linear-gradient(to right,#142241 0%,#0e4798 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  goldTitle: {
    display: 'inline-block',
    position: 'relative',
    color: theme.palette.secondary.dark,
    background: 'linear-gradient(to right,#e9b109 0%,#9b7501 100%)',
    textShadow: 'none',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  }
}));

const PageTitle = ({ page, ignoreSkeleton, classes }) => {
  const _classes = { ...useStyles(), ...classes };
  if (!page && !ignoreSkeleton) {
    return (
      <Skeleton
        variant="text"
        width={400}
        height={48}
        className={clsx(_classes.middle)}
      />
    );
  }
  let leftTitle = '';
  let rightTitle = '';
  let title = '';
  try {
    [title, leftTitle, rightTitle] = page.title.match(/(.*\s)(\b\w*)$/i);
  } catch (e) {}

  return (
    <div className={_classes.root}>
      <Typography variant="h3" component="h1" className={_classes.pageHeading}>
        <span className={_classes.blueTitle}>{leftTitle}</span>{' '}
        <span className={_classes.goldTitle}>{rightTitle}</span>
      </Typography>
    </div>
  );
};

PageTitle.propTypes = {
  page: PropTypes.object,
  ignoreSkeleton: PropTypes.bool
};

PageTitle.defaultProps = {
  classes: {}
};

export default PageTitle;

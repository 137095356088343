import React, {useEffect, useRef, useState} from 'react';
import Image from 'next/image';
import {CardContent, CardMedia, Typography, Box} from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {useApolloClient, useMutation} from '@apollo/client';
import * as PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import {useSnackbar} from 'notistack';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  GoldCheckoutCreate,
  TrackQuickBuyWidgetMutation
} from 'src/gql/mutations/checkout';
import useBackdropLoading from 'src/hooks/useBackdropLoading';
import {
  getRsnFromStorage,
  maybe,
  saveCheckoutDataInStorage,
  saveRsnInStorage
} from 'src/core/utils';
import {GameVersionEnum, ProductTypeEnum} from 'src/core/enums';

import FilledInput from '../FormikFilledInput';
import {useStyles} from './styled';
import {roundTo, cleanupInput} from '../../core/utils';
import useStorefrontData from '../../hooks/useStorefrontData';
import {MainButton} from '../Button';
import {PageSlugs} from '../../core/urls';
import {CalculateLoyaltyPointsMutation} from '../../gql/mutations/general';
import useCheckoutBoost from '../../hooks/useCheckoutBoost';

const GoldCurrencySwitcher = dynamic(
  () => import('src/components/GoldCurrencySwitcher')
);

const defaultValues = {
  [GameVersionEnum.OSRS]: 100,
  [GameVersionEnum.RS3]: 500
};

const BuyGoldCard = ({
  product,
  currency,
  isWidget,
  hideMediaOnMobile,
  buttonOnly
}) => {
  const router = useRouter();
  const isMounted = useRef(true);
  const classes = useStyles();
  const {user} = useStorefrontData();
  const client = useApolloClient();
  const boostCheckout = useCheckoutBoost();
  const {showLoading, stopLoading} = useBackdropLoading();
  const {enqueueSnackbar} = useSnackbar();
  const [formValues, setFormValues] = useState(null);
  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const formRef = useRef(null);

  const [trackQuickBuyWidget] = useMutation(TrackQuickBuyWidgetMutation);
  const [goldCheckoutCreate] = useMutation(GoldCheckoutCreate, {
    onError: error => {
      stopLoading();
      enqueueSnackbar('Unexpected error, please contact our support.', {
        variant: 'error'
      });
    }
  });
  const [calculateLoyaltyPoints] = useMutation(CalculateLoyaltyPointsMutation, {
    onCompleted: ({calculateLoyaltyPoints}) =>
      setLoyaltyPoints(calculateLoyaltyPoints.points)
  });

  const sellPrice = product.sellPriceInCurrencies.find(
    priceItem => priceItem.currency === currency
  );

  const emailInput = maybe(() => formRef.current.values.email, null);
  const rsnInput = maybe(() => formRef.current.values.rsn, null);

  // this is used for reinit (when we pass new currency)
  const initialFormValues = formValues || {
    rsn: user
      ? getRsnFromStorage(product.gameVersion, ProductTypeEnum.GOLD)
      : rsnInput || '',
    email: user ? user.email : emailInput || '',
    quantity: defaultValues[product.gameVersion],
    // price: roundTo.down(product.minSellQuantity * sellPrice.amount, 2),
    price: roundTo(defaultValues[product.gameVersion] * sellPrice.amount, 2),
    currency
  };

  useEffect(() => {
    if (isMounted.current && user) {
      calculateLoyaltyPoints({
        variables: {
          priceAmount: initialFormValues.price,
          currency: initialFormValues.currency
        }
      });
      isMounted.current = false;
    }
  }, [initialFormValues, user]);

  const Schema = Yup.object().shape({
    rsn: Yup.string()
      .max(12, 'Too Long!')
      .min(1, 'Too Short!')
      .matches(
        /^[a-zA-Z0-9-_ ]+$/,
        'Use only numbers, letters, spaces and hyphens(-)'
      )
      .transform((value, originalValue) => value.trim())
      .required('This field is required.'),
    email: Yup.string()
      .email('Not valid email.')
      .required('This field is required.'),
    quantity: Yup.number()
      .min(
        product.minSellQuantity,
        `THE MINIMUM ${product.name} AMOUNT IS ${product.minSellQuantity}M!`
      )
      .required('This field is required.'),
    price: Yup.number()
  });

  const syncValues = (targetValue, targetFieldName, values, setValues) => {
    const valuesToUpdate = {...values};

    if (isNaN(parseFloat(targetValue))) {
      return false;
    }

    let performUpdate = false;

    if (targetFieldName === 'quantity') {
      const price = roundTo(sellPrice.amount * targetValue, 2);
      valuesToUpdate.quantity = targetValue;
      valuesToUpdate.price = price;
      valuesToUpdate.currency = currency;
      performUpdate = price !== values.price;
    } else if (targetFieldName === 'price') {
      const quantity = roundTo(targetValue / sellPrice.amount, 2);
      valuesToUpdate.price = targetValue;
      valuesToUpdate.quantity = quantity;
      performUpdate = quantity !== values.quantity;
    } else {
      return;
    }

    if (performUpdate) {
      if (user) {
        calculateLoyaltyPoints({
          variables: {
            priceAmount: valuesToUpdate.price,
            currency
          }
        });
      }

      setValues(valuesToUpdate);
      setFormValues(valuesToUpdate);
    }
  };

  if (formValues && formValues.currency !== currency) {
    // update state values which are used for initial formik values while
    // we are using 'enableReinitialize' option
    syncValues(formValues.quantity, 'quantity', formValues, setFormValues);
  }

  const handleSubmit = async values_ => {
    const values = Schema.cast(values_);
    showLoading();
    // await loadPaymentCountries();
    await boostCheckout();

    if (isWidget) {
      const pageUrl = router.asPath.split('?')[0];
      await trackQuickBuyWidget({
        variables: {input: {page: pageUrl, email: values.email}}
      });
    }

    const {data} = await goldCheckoutCreate({
      variables: {
        input: {
          product: product.id,
          currency,
          email: values.email,
          rsn: values.rsn,
          quantity: values.quantity
        }
      }
    });
    const {errors, checkout, created} = data.goldCheckoutCreate;

    if (errors.length > 0) {
      errors.map(error => enqueueSnackbar(error.message, {variant: 'error'}));
      stopLoading();
      return null;
    }

    if (created) {
      saveCheckoutDataInStorage(checkout, client);
      saveRsnInStorage(values.rsn, product.gameVersion, ProductTypeEnum.GOLD);

      // redirect to checkout page
      router.push(
        `/checkout/summary/[productType]?token=${checkout.token}`,
        `/checkout/summary/${ProductTypeEnum.GOLD.toLowerCase()}?token=${
          checkout.token
        }`
      );
      return null;
    }

    showLoading();

    enqueueSnackbar(
      'Something went wrong, please try again or contact our support team.',
      {variant: 'error'}
    );
  };

  const openCurrencyDropdown = e => {
    setCurrencyAnchorEl(e.currentTarget);
  };

  const closeCurrencyDropdown = e => {
    setCurrencyAnchorEl(null);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialFormValues}
      validationSchema={Schema}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({values, setValues}) => (
        <div
          className={clsx(
            classes.root,
            hideMediaOnMobile && classes.notRoundedCorner,
            !isWidget && classes.ornament
          )}
        >
          {!isWidget && (
            <HiddenCss xsDown={Boolean(hideMediaOnMobile)}>
              <div className={classes.cardHeader}>
                <Typography
                  component='div'
                  className={classes.goldTitle}
                  align='center'
                >
                  {product.name}
                </Typography>
                <Typography
                  component='div'
                  className={classes.sellPrice}
                  align='center'
                  suppressHydrationWarning
                >
                  {sellPrice.localized}
                  <span>/M</span>
                </Typography>
              </div>
            </HiddenCss>
          )}
          <CardContent
            className={clsx(classes.content, isWidget && classes.widgetContent)}
          >
            <Form>
              <div className={classes.prices}>
                <div className={clsx(classes.priceItem, classes.priceItemLeft)}>
                  <div className={classes.pricePrefix}>
                    <Typography
                      component='div'
                      className={classes.pricePrefixSign}
                    >
                      M
                    </Typography>
                    <Typography
                      component='div'
                      className={classes.pricePrefixText}
                    >
                      amount
                    </Typography>
                  </div>
                  <FilledInput
                    name='quantity'
                    // id="quantity"
                    variant='filled'
                    placeholder='0'
                    numeric
                    size='small'
                    InputProps={{
                      className: classes.fieldInput
                    }}
                    className={clsx(classes.priceInput, classes.input)}
                    onChangeCapture={e => {
                      syncValues(e.target.value, 'quantity', values, setValues);
                    }}
                    FormHelperTextProps={{
                      className: classes.quantityError
                    }}
                  />
                </div>
                <div className={classes.imageWrapper}>
                  <Image
                    src='/images/2-layers.png'
                    alt='sync'
                    loading='eager'
                    layout='fill'
                    objectFit='contain'
                  />
                </div>
                <div
                  className={clsx(classes.priceItem, classes.priceItemRight)}
                >
                  <div
                    className={clsx(classes.pricePrefix, classes.hover)}
                    onClick={openCurrencyDropdown}
                  >
                    <Typography
                      component='div'
                      className={classes.pricePrefixSign}
                      suppressHydrationWarning
                    >
                      {sellPrice.currency}
                    </Typography>
                    <Typography
                      component='div'
                      className={classes.pricePrefixText}
                    >
                      amount
                    </Typography>
                    <div className={classes.dropDownIcon}>
                      {!currencyAnchorEl ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandLessIcon />
                      )}
                    </div>
                  </div>
                  <GoldCurrencySwitcher
                    anchorEl={currencyAnchorEl}
                    handleClose={closeCurrencyDropdown}
                  />
                  <FilledInput
                    name='price'
                    // id="price"
                    placeholder='0'
                    variant='filled'
                    numeric
                    size='small'
                    InputProps={{
                      className: classes.fieldInput
                    }}
                    onChangeCapture={e => {
                      syncValues(e.target.value, 'price', values, setValues);
                    }}
                    className={clsx(classes.priceInput, classes.input)}
                  />
                </div>
              </div>
              {!buttonOnly && (
                <>
                  <Box mt={0.625} mb={1}>
                    <FilledInput
                      fullWidth
                      name='rsn'
                      placeholder='Your Character Name'
                      variant='filled'
                      size='small'
                      onChange={() => setFormValues(values)}
                      className={clsx(classes.input, classes.textInput)}
                      FormHelperTextProps={{
                        className: classes.inputError
                      }}
                      InputProps={{
                        className: classes.fieldInput
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <PersonOutlineIcon className={classes.fieldIcon} />
                        //   </InputAdornment>
                        // )
                      }}
                    />
                  </Box>
                  <Box>
                    <FilledInput
                      fullWidth
                      name='email'
                      placeholder='Your Email'
                      disabled={Boolean(user)}
                      variant='filled'
                      size='small'
                      onChange={() => setFormValues(values)}
                      className={clsx(classes.input, classes.textInput)}
                      FormHelperTextProps={{
                        className: classes.inputError
                      }}
                      InputProps={{
                        className: classes.fieldInput
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailOutlineIcon className={classes.fieldIcon} />
                        //   </InputAdornment>
                        // )
                      }}
                    />
                  </Box>
                </>
              )}
              {/* <Box mt={2.75}/> */}
              <Box mt={2} />
              {user && (
                <div className={classes.pointsTextBox}>
                  <Typography align='center' className={classes.pointsText}>
                    Earn {loyaltyPoints} loyalty points
                  </Typography>
                </div>
              )}
              <MainButton
                color='secondary'
                type={buttonOnly ? 'button' : 'submit'}
                // disabled={}
                onClick={() => {
                  if (buttonOnly) {
                    const slug =
                      product.gameVersion === GameVersionEnum.OSRS
                        ? PageSlugs.OSRS
                        : PageSlugs.RS3;
                    router.push(`/${slug}`);
                  }
                }}
                arrows
                fullWidth
                className={classes.button}
                classes={{
                  label: classes.buttonLabel
                }}
              >
                Buy {product.gameVersion} gold
              </MainButton>
            </Form>
          </CardContent>
        </div>
      )}
    </Formik>
  );
};

BuyGoldCard.propTypes = {
  currency: PropTypes.string,
  product: PropTypes.object,
  isWidget: PropTypes.bool,
  hideMediaOnMobile: PropTypes.bool
};

export default BuyGoldCard;
